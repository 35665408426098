:root {
  --darkpurple: #3d1b4f;
  --lightpurple: #d8bbd3;
  --lightblue: #d1edea;
  --white: #fff;
  --black: #000;
  --darkgrey: #727272;
  --lightgrey: #fafafa;
  --lightgrey2: #e5e5e5;
}

body {
  font-family: "Fredoka Variable", sans-serif;
}

.app {
  display: flex;
}

.nav-container {
  width: 20%;
}

.content-container {
  width: 85%;
}

.library {
  margin: 40px;
}

.empty-state {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
}

/* layout */
.space-between {
  justify-content: space-between;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

/* typography */
h1 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

h2 {
  font-size: 21px;
  font-weight: 500;
  margin: 0;
}

h3 {
  font-size: 16px;
  font-weight: 400;
}

p {
  margin: 0;
  font-size: 14px;
}

.paragraph-height {
  line-height: 28px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* components */

.nav {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 40px;
  background-color: var(--darkpurple);
  height: 100vh;
  position: fixed;
  width: 18%;
  top: 0;
  left: 0;
  overflow-y: auto;
  box-sizing: border-box; /* Ensure padding is included in height */
}

.nav-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 40px;
  background-color: var(--darkpurple);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  box-sizing: border-box; /* Ensure padding is included in height */
}

.mobile-menu-margin {
  margin-top: 24px;
}

.nav-mobile-content {
  display: block !important;
}

.nav-btn {
  display: none;
}

.nav-logo {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  column-gap: 12px;
  padding-left: 8px;
  padding-bottom: 24px;
  color: var(--lightpurple);
}

.mobile-hide-logo {
  display: none;
}

.menu-label {
  font-size: 12px;
  color: var(--lightpurple);
  margin-bottom: 12px;
  margin-left: 8px;
}

.menu-item {
  padding: 8px 12px;
  font-size: 14px;
  color: var(--white);
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 100px;
  margin-bottom: 8px;
}

.menu-active {
  background-color: var(--lightblue);
  color: var(--darkpurple);
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.64);
}

.menu-counter {
  font-size: 12px;
  font-weight: 300;
}

.menu-item:not(.menu-active):hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.24);
}

.input {
  border-radius: 16px;
  border: 1px solid var(--lightgrey2);
  margin-top: 4px;
  padding: 8px;
  font-family: "Fredoka Variable", sans-serif;
  width: 50%;
}

/* margins */
.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

/* widths */
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}

/* form */

.shopping-list {
  padding: 24px;
  border-radius: 24px;
  background-color: var(--lightgrey);
}

/* buttons */
.primary-btn {
  background-color: var(--darkpurple);
  color: var(--lightpurple);
  border: 1px solid transparent;
  border-radius: 16px;
  font-family: "Fredoka Variable", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
}

.secondary-btn {
  background-color: var(--white);
  color: var(--darkpurple);
  border: 1px solid var(--lightgrey2);
  border-radius: 16px;
  font-family: "Fredoka Variable", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
}

.outline-btn {
  background-color: var(--white);
  font-family: "Fredoka Variable", sans-serif;
  border: 1px solid var(--lightgrey2);
  padding: 8px 16px;
  border-radius: 16px;
}

.text-btn {
  color: var(--darkgrey);
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 8px;
  padding: 4px 0px;
  font-family: "Fredoka Variable", sans-serif;
}

.text-btn:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.button-group {
  display: flex;
  column-gap: 8px;
  width: 100%;
}

.icon-btn {
  padding: 8px 10px;
  border-radius: 24px;
  background-color: transparent;
  color: var(--white);
  border: none;
}

.icon-btn:hover {
  background-color: var(--lightpurple);
  color: var(--darkpurple);
  cursor: pointer;
}

.full-width-btn {
  width: 100%;
  font-size: 16px;
  border-radius: 100px;
}

.badge {
  font-size: 14px;
  padding: 4px 8px;
  background-color: var(--lightpurple);
  color: var(--darkpurple);
  border-radius: 16px;
}

/* grid */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 20px;
}

.grid-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.grid-thumbnail:hover {
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(159, 159, 159, 0.24);
}

/* detailed view */
.detail-thumbnail {
  width: 90%;
  height: 650px;
  object-fit: cover;
  border-radius: 16px;
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
}

.item-card {
  width: 100%;
  height: 180px;
  border-radius: 24px;
  border: 1px solid var(--lightgrey2);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.item-card p {
  padding: 16px;
}

.item-card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(159, 159, 159, 0.24);
}

.item-thumbnail {
  width: auto;
  height: 50%;
  object-fit: cover;
  border-radius: 16px;
}

/* responsiveness */
@media only screen and (max-width: 1024px) {
  .app {
    flex-direction: column;
    height: 100vh;
  }

  .nav-content {
    display: none;
  }

  .nav {
    width: 100%;
    height: auto;
    padding-bottom: 24px;
  }

  .nav-mobile {
    height: 100vh;
  }

  .nav-logo {
    padding-bottom: 0px;
  }

  .nav-btn {
    display: flex;
  }

  .nav-container {
    width: 100%;
  }
  .content-container {
    width: 100%;
    margin-top: 80px;
  }

  .w50 {
    width: 80%;
  }
}
